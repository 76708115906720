<template>
  <div :class="`auth-v1 px-2 ${isSubmitted ? 'pt-2' : 'auth-wrapper'}`">
    <div v-show="!isSubmitted" class="auth-inner py-2">
      <!-- Loading -->
      <loading
        v-if="isLoading || isSubmitting"
        :active="true" 
        :is-full-page="true"
        color="#498ceb"
      />

      <b-card v-if="!isLoading" class="mb-0">
        <!-- Logo -->
        <b-link class="brand-logo">
          <add-co-logo />
          <h2 class="brand-text text-primary font-weight-bolder">
            AddCo
          </h2>
        </b-link>

        <!-- Not found message -->
        <b-card v-if="clientNotFound">
          <h2 class="text-danger">
            {{ $t('client_not_found_check_url') }}
          </h2>
        </b-card>

        <div v-else>
          <!-- Title -->
          <b-card-title class="mb-1">
            {{ $t('complaintsFollowUp') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('enterUserAndCaseNumbers') }}:
          </b-card-text>
  
          <!-- Form -->
          <validation-observer
            ref="followUpForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="submitData"
            >
  
              <!-- User number -->
              <b-form-group :label="$t('message.user_number')" label-for="userNumber">
                <validation-provider :name="$t('message.user_number')" rules="required" #default="{ errors }">
                  <b-form-input
                    v-model="userNumber"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('message.user_number')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <!-- Case number -->
              <b-form-group :label="$t('message.case_number')" label-for="caseNumber">
                <validation-provider :name="$t('message.case_number')" rules="required" #default="{ errors }">
                  <b-form-input
                    v-model="caseNumber"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('message.case_number')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
    
              <!-- Submit -->
              <b-button variant="primary" type="submit" class="mt-2" block :disabled="invalid">
                {{ $t('message.submit') }}
              </b-button>
            </b-form>
          </validation-observer>

          <div class="my-2" style="border-top: 1px solid #ebe9f1;" />

          <!-- New complaint -->
          <b-card-text class="mb-2">
            {{ $t('ifWishNewComplaint') }}:
          </b-card-text>

          <b-button variant="warning" block @click="goToNewComplaint">
            {{ $t('New Complaint') }}
          </b-button>
        </div>
      </b-card>
    </div>

    <!-- Complaint form -->
    <div v-show="isSubmitted">
      <!-- Go back button -->
      <b-card>
        <b-button variant="secondary" @click="isSubmitted = false">
          <span class="mr-1">&#11148;</span>{{ $t('Back') }}
        </b-button>
      </b-card>

      <!-- Form -->
      <Form
        :data="complaintData"
        :isSubmitting="false"
        :updateActiveTab="updateActiveTab"
        :readOnly="true"
        :userType="userType"
        :clientId="clientData._id"
        @addNewMessage="addNewMessage"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, set } from "@vue/composition-api"
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BLink } from 'bootstrap-vue'
import { required, email } from '@validations'
import { useRouter } from '@core/utils/utils'
import { getClient } from '@/@core/queries/clients'
import { getComplaint } from '@/@core/queries/complaints'
import AddCoLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import axios from "@axios"
import useNotifications from "@/composables/useNotifications"
import Form from '@/views/organization/complaint/shared/Form.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    AddCoLogo,
    ValidationProvider,
    ValidationObserver,
    Form,
    Loading,
  },
  setup() {
    const { showErrorMessage } = useNotifications();
    const { route, router } = useRouter()
    const { updateItem } = realmConnection()
    const { clientPublicId } = route.value.params
    const userNumber = ref('')
    const caseNumber = ref('')
    const clientData = ref({})
    const clientNotFound = ref(false)
    const isLoading = ref(true)
    const isSubmitting = ref(false)
    const isSubmitted = ref(false)
    const complaintData = ref({})
    const updateActiveTab = ref(false)
    const collection = 'complaint'
    const userType = ref('')

    const submitData = () => {
      isSubmitting.value = true

      const query = {
        caseNumber: caseNumber.value,
        OR: [
          { authorUserNumber: userNumber.value },
          { affectedUserNumber: userNumber.value },
          { reportedUserNumber: userNumber.value },
        ]
      }

      axios
        .post('/graphql', {
          query: getComplaint,
          variables: { query },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          if (!data?.data?.complaint) throw new Error("invalidUserAndCaseNumbers")

          const c = data.data.complaint

          complaintData.value = {
            ...c,
            themesComputed: computed(() => c.themes?.map(theme => theme.isNewOption ? theme.value : i18n.t(`complaintThemes.${theme.value}`)).join(" / ")),
            companyRelationshipComputed: c.companyRelationship ? computed(() => c.companyRelationship.isNewOption ? c.companyRelationship.value : i18n.t(`companyRelationships.${c.companyRelationship.value}`)) : '',
            reportedPerson: getName(c.reportedPerson?.name),
            reportedPersonResponsible: getName(c.reportedPersonResponsible?.name),
            areas: c.areas?.map(({ location }) => getName(location)),
            associatedCases: c.associatedCases?.map(e => e.note).join(" / "),
            proposalResponsible: i18n.t(`label.${c.proposalResponsible?.name ? 'responsible_assigned' : 'responsible_to_assign'}`),
            approvalResponsible: i18n.t(`label.${c.approvalResponsible?.name ? 'responsible_assigned' : 'responsible_to_assign'}`),
            verificationResponsible: i18n.t(`label.${c.verificationResponsible?.name ? 'responsible_assigned' : 'responsible_to_assign'}`),
            actionPlans: c.actionPlans?.map(e => ({ ...e, executionResponsible: i18n.t(`label.${e.executionResponsible?.name ? 'responsible_assigned' : 'responsible_to_assign'}`) })) || [],
            state: c.stage === 'end' ? 'completed' : 'upToDate'
          }

          // Set reactive properties
          complaintData.value.actionPlans.forEach(e => {
            set(e, 'approvalNextStage', e.stage === 'approval' || e.stage === 'end' ? '' : e.stage)
            set(e, 'correctiveNextStage', e.stage === 'analysis' || e.stage === 'verification' ? e.stage : '')
            set(e, 'verificationNextStage', e.stage === 'verification' ? '' : e.stage)
            set(e, 'evidenceFile', [])
          })

          // Check each action plan end date to see if the complaint is delayed
          if (complaintData.value.state === 'upToDate') {
            for (const actionPlan of complaintData.value.actionPlans) {
              if (actionPlan.endDate && new Date(actionPlan.endDate) < new Date()) {
                complaintData.value.state = 'delayed'
                break
              }
            }
          }

          // Determine the type of user that is reviewing the complaint
          userType.value = c.authorUserNumber === userNumber.value ? 'author' : c.affectedUserNumber === userNumber.value ? 'affected' : 'reported'

          updateActiveTab.value = !updateActiveTab.value
          isSubmitted.value = true
        })
        .catch(error => {
          console.log(error)
          showErrorMessage(i18n.t(`message.${error?.message === 'invalidUserAndCaseNumbers' ? 'invalidUserAndCaseNumbers' : 'complaint_fetch_error'}`))
        })
        .finally(() => {
          isSubmitting.value = false
        })
    }

    const getName = (name) => {
      return name?.startsWith('other_') ? i18n.t(`label.${name}`) : name
    }

    const goToNewComplaint = () => {
      const routeData = router.resolve({ name: 'complaint-new', params: { clientPublicId } })
      window.open(routeData.href, '_blank')
    }

    const addNewMessage = (message) => {
      try {
        const query = { caseNumber: caseNumber.value }
        const action = { $push: { messages: message } }
        updateItem({ collection, query, action })
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      try {
        // If route doesn't have the client public id, throw error
        if (!clientPublicId) throw new Error("Client public id not present in route params")

        // If user is not logged in (either as a real or anonymous user), then log in as an anonymous user
        if (!useJwt.realm.currentUser) await useJwt.loginAsAnonymousUser()

        // Get client data based on client public id from URL param
        const { data } = await axios.post("/graphql", {
          query: getClient,
          variables: { query: { publicId: clientPublicId } },
        })

        if (data.errors) throw new Error(data.errors[0].message)
        if (!data.data?.client) throw new Error("Client not found")
        clientData.value = data.data.client

        // Define locale based on client default language
        i18n.locale = clientData.value.default_language
        localize(clientData.value.default_language)

      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.err_client_fetch'))
        clientNotFound.value = true

      } finally {
        isLoading.value = false
      }
    })

    return {
      userNumber,
      caseNumber,
      required,
      email,
      clientNotFound,
      isLoading,
      submitData,
      router,
      goToNewComplaint,
      isSubmitting,
      isSubmitted,
      complaintData,
      updateActiveTab,
      addNewMessage,
      clientData,
      userType,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
