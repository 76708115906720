export const getComplaints = `query getComplaints($query: ComplaintQueryInput, $limit: Int!, $sortBy: ComplaintSortByInput!){
  complaints(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    description
  }
}`

export const getComplaint = `query getComplaint($query: ComplaintQueryInput){
  complaint(query: $query) {
    _id
    description
    themes {
      value
      isNewOption
    }
    stage
    documentKey
    createdAt
    dueDate
    authorUserNumber
    affectedUserNumber
    reportedUserNumber
    caseNumber
    companyRelationship {
      value
      isNewOption
    }
    areas {
      _id
      location
    }
    associatedCases {
      _id
      note
    }
    reportedPerson {
      _id
      name
    }
    reportedPersonResponsible {
      _id
      name
    }
    proposalResponsible {
      _id
      name
      email
    }
    approvalResponsible {
      _id
      name
      email
    }
    verificationResponsible {
      _id
      name
      email
    }
    approvalDueDate
    verificationDueDate
    approvalComment
    verificationComment
    problemStatement
    reportConclusion
    why1
    why2
    why3
    why4
    why5
    why6
    why7
    why8
    why9
    why10
    isCompletedOverdue
    actionPlans {
      _id
      type
      startDate
      endDate
      name
      description
      evidence_key
      executionDate
      stage
      approvalResult
      approvalComment
      correctiveResult
      correctiveComment
      verificationResult
      verificationComment
      implementationResult
      implementationComment
      finalizedResult
      finalizedComment
      executionResponsible {
        _id
        name
      }
      originIndex
    }
    messages {
      sender
      text
      tab
      createdAt
      evidenceKey
      receiver
    }
    affectedPerson {
      name
      rut
      email
      company
    }
    author {
      name
      rut
      email
      company
    }
    reportedPersonData {
      name
      rut
      email
      position
      company
    }
    isKarinLaw
  }
}`

export const queryComplaintsData = `query ComplaintsData($input: ComplaintsDatum!) {
  ComplaintsData(input: $input) {
    complaintsPaginated {
      _id
      themes {
        value
        isNewOption
      }
      caseNumber
      dueDate
      state
      stage
      isCompletedOverdue
    }
    complaintsStats {
      stateCounts {
        labels
        values
        statsForCards {
          completed
          delayed
          upToDate
        }
      }
      stageCounts {
        labels
        values
      }
      totalCount
    }
  }
}`

export const addComplaint = `mutation insertOneComplaint($data: ComplaintInsertInput!){
  insertOneComplaint(data: $data) {
    _id
  }
}`

export const updateComplaint = `mutation updateOneComplaint($query: ComplaintQueryInput!, $data: ComplaintUpdateInput!) {
  updateOneComplaint(query: $query, set: $data) {
    _id
  }
}`