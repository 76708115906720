<template lang="pug">
b-row
  b-col(cols="12")
    form-wizard.wizard-vertical.mb-3(
      color="#498ceb",
      layout="horizontal",
      :title="null",
      :subtitle="null",
      :finish-button-text="$t('message.submit')",
      :back-button-text="$t('message.previous')",
      :next-button-text="$t('message.next')",
      :hide-buttons="readOnly",
      @on-complete="formSubmitted",
      ref="complaintForm"
    )
      // Title
      h4(v-if="data.state || data.stage" slot="title", class="pt-1 mb-0")
        span(v-if="data.state")
          span
            | {{ $t('improvement_opp.state') }} &#11166; 
          span(:class="data.state === 'completed' ? 'text-success' : data.state === 'delayed' ? 'text-danger' : 'text-primary'", style="font-weight: 600")
            | {{ $t(data.state) }}
          span(v-if="data.state === 'completed' && data.isCompletedOverdue", class="text-danger", style="font-weight: 600")
            |  ({{ $t('delayed') }})
        span(v-if="data.state && data.stage", class="mx-1")  | 
        span(v-if="data.stage")
          span
            | {{ $t('actualStage') }} &#11166; 
          span(class="text-primary", style="font-weight: 600")
            | {{ $t(`complaintStages.${data.stage}`) }}

      // Identification
      tab-content(:title="$t('label.identification')", :before-change="() => validateTab(identificationTab)")
        validation-observer(ref="identificationTab", tag="form")
          b-row
            // Description
            b-col(md="12")
              b-form-group(:label="$t(`label.${data.isKarinLaw ? 'factsThatAreReported' : 'complaint'}`)", :label-for="$t('label.complaint')")
                b-form-textarea(v-model="data.description", :disabled="true")
          
          b-row
            // Themes
            b-col(md="6")
              b-form-group(:label="$t('label.themes')", :label-for="$t('label.themes')")
                validation-provider(:name="$t('label.themes')", rules="required", v-slot="{ errors }")
                  b-form-input(v-model="data.themesComputed", trim, :disabled="true")
                  small(class="text-danger") {{ errors[0] }}
          
            // Document
            b-col(md="6")
              b-form-group(:label="$t(`label.${data.isKarinLaw ? 'additionalBackground' : 'document'}`)", :label-for="$t('label.document')")
                // Open Document
                b-button(
                  v-if="data.documentKey && data.documentKey.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  @click="openDocument(data.documentKey)"
                )
                  | {{ $t('message.open') }}

                // No document message
                b-form-input(
                  v-else
                  v-model="noDocumentMessage"
                  :disabled="true"
                )

          b-row
            // Associated Cases
            b-col(md="6")
              b-form-group(:label="$t('label.associated_cases')", :label-for="$t('label.associated_cases')")
                v-select(
                  v-model="data.associatedCases"
                  label="title"
                  :multiple="!readOnly && stages[data.stage] <= 2"
                  :options="improvements"
                  :reduce="improvement => improvement.value"
                  :disabled="readOnly || stages[data.stage] > 2"
                  :placeholder="$t('placeholder.associated_cases')"
                )

            // Link to Associated Case
            //- b-col(md="6")
            //-   b-form-group(:label="$t('label.associated_case_link')", :label-for="$t('label.associated_case_link')")
            //-     b-button(
            //-       variant="warning"
            //-       size="sm"
            //-       :style="{ marginTop: '4px' }"
            //-       :disabled="readOnly || !data.associatedCase"
            //-       @click="openCase(data.associatedCase)"
            //-     )
            //-       | {{ $t('message.see_case') }}
          
            // Company Relationship
            b-col(md="6")
              b-form-group(:label="$t(`label.${data.isKarinLaw ? 'organizationalLinkBetweenTheParties' : 'companyRelationship'}`)", :label-for="$t('label.companyRelationship')")
                b-form-input(v-model="data.companyRelationshipComputed", trim, :disabled="true")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.identificationTab", tab="identificationTab", :userType="userType", :clientId="clientId", @add-message="addMessage")

      // Responsibles
      tab-content(:title="$t('message.responsibles')", :before-change="() => validateTab(responsiblesTab)")
        validation-observer(ref="responsiblesTab", tag="form") 
          b-row(v-if="!data.isKarinLaw")
            // Operational Areas
            b-col(md="12")
              b-form-group(:label="$t('label.operationalAreas')", :label-for="$t('label.operationalAreas')")
                v-select(
                  v-model="data.areas"
                  label="title"
                  :options="locations"
                  :reduce="location => location.value"
                  :disabled="true"
                )

          b-row(v-if="!data.isKarinLaw")
            // Reported Person
            b-col(md="6")
              b-form-group(:label="$t('label.reported_person')", :label-for="$t('label.reported_person')")
                validation-provider(:name="$t('label.reported_person')", rules="required", v-slot="{ errors }")
                  v-select(
                    v-model="data.reportedPerson"
                    label="title"
                    :options="workersTotal"
                    :reduce="worker => worker.value"
                    :disabled="true"
                  )
                  small(class="text-danger") {{ errors[0] }}
            
            // Reported Person Responsible
            b-col(md="6")
              b-form-group(:label="$t('label.reported_person_responsible')", :label-for="$t('label.reported_person_responsible')")
                v-select(
                  v-model="data.reportedPersonResponsible"
                  label="title"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :disabled="true"
                )

          b-row(v-if="data.isKarinLaw")
            // Author
            b-col(md="4")
              b-form-group(:label="$t('label.whistleblower')" label-for="whistleblower" label-class="font-weight-bold")
                // Name
                label {{ $t('label.Name') }}
                div
                  validation-provider(:name="`${$t('label.Name')} 1`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.author.name"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.name')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // RUT
                label {{ $t('message.tableHeader.rut') }}
                div
                  validation-provider(:name="`${$t('message.tableHeader.rut')} 1`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.author.rut"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.rut')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // Email
                label {{ $t('label.Email') }}
                div
                  validation-provider(:name="`${$t('label.Email')} 1`" rules="required|email" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.author.email"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.email')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // Company
                label {{ $t('label.company') }}
                div
                  validation-provider(:name="`${$t('label.company')} 1`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.author.company"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.company')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // User Number
                label {{ $t('message.user_number') }}
                b-form-input(
                  v-model="data.authorUserNumber"
                  trim
                  :disabled="true"
                )

            // Affected Person
            b-col(md="4")
              b-form-group(:label="$t('label.affectedPerson')" label-for="affectedPerson" label-class="font-weight-bold")
                // Name
                label {{ $t('label.Name') }}
                div
                  validation-provider(:name="`${$t('label.Name')} 2`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.affectedPerson.name"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.name')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // RUT
                label {{ $t('message.tableHeader.rut') }}
                b-form-input(
                  v-model="data.affectedPerson.rut"
                  trim
                  :disabled="true"
                  :placeholder="$t('placeholder.rut')"
                )

                // Email
                label {{ $t('label.Email') }}
                div
                  validation-provider(:name="`${$t('label.Email')} 2`" rules="required|email" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.affectedPerson.email"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.email')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // Company
                label {{ $t('label.company') }}
                div
                  validation-provider(:name="`${$t('label.company')} 2`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.affectedPerson.company"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.company')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // User Number
                label {{ $t('message.user_number') }}
                b-form-input(
                  v-model="data.affectedUserNumber"
                  trim
                  :disabled="true"
                )

            // Reported Person
            b-col(md="4")
              b-form-group(:label="$t('label.reported_person')" label-for="reported_person" label-class="font-weight-bold")
                // Name
                label {{ $t('label.Name') }}
                div
                  validation-provider(:name="`${$t('label.Name')} 3`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.reportedPersonData.name"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.name')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // RUT
                label {{ $t('message.tableHeader.rut') }}
                b-form-input(
                  v-model="data.reportedPersonData.rut"
                  trim
                  :disabled="true"
                  :placeholder="$t('placeholder.rut')"
                )

                // Email
                label {{ $t('label.Email') }}
                div
                  validation-provider(:name="`${$t('label.Email')} 3`" rules="email" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.reportedPersonData.email"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.email')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // Position
                label {{ $t('label.position') }}
                div
                  validation-provider(:name="`${$t('label.position')} 3`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.reportedPersonData.position"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.position')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // Company
                label {{ $t('label.company') }}
                div
                  validation-provider(:name="`${$t('label.company')} 3`" rules="required" v-slot="{ errors }")
                    b-form-input(
                      v-model="data.reportedPersonData.company"
                      trim
                      :disabled="true"
                      :placeholder="$t('placeholder.company')"
                    )
                    small(class="text-danger") {{ errors[0] }}

                // User Number
                label {{ $t('message.user_number') }}
                b-form-input(
                  v-model="data.reportedUserNumber"
                  trim
                  :disabled="true"
                )
          
          b-row
            // Registration Responsible (Complainant Number)
            b-col(md="6")
              b-form-group(:label="$t('label.registration_responsible_complainant_number')", :label-for="$t('label.registration_responsible_complainant_number')")
                b-form-input(
                  v-model="data.caseNumber",
                  trim,
                  :disabled="true"
                )

            // Date
            b-col(md="3")
              b-form-group(:label="$t('label.date')", :label-for="$t('label.date')")
                flat-pickr(
                  v-model="data.createdAt"
                  :config="flatPickrConfig"
                  class="form-control"
                  :disabled="true"
                )

          b-row
            // Proposal Responsible
            b-col(md="6")
              b-form-group(:label="$t('label.investigation_responsible')", :label-for="$t('label.investigation_responsible')")
                validation-provider(:name="$t('label.investigation_responsible')", rules="required", v-slot="{ errors }")
                  v-select(
                    v-model="data.proposalResponsible"
                    label="title"
                    :options="workersTotal"
                    :reduce="worker => worker.value"
                    :disabled="readOnly || stages[data.stage] > 2"
                    :placeholder="$t('placeholder.investigation_responsible')"
                  )
                  small(class="text-danger") {{ errors[0] }}

            // Due date for proposal responsible
            b-col(md="3")
              b-form-group(:label="$t('label.due_date')", :label-for="$t('label.due_date')")
                flat-pickr(
                  v-model="data.dueDate"
                  :config="flatPickrConfig"
                  class="form-control"
                  :disabled="true"
                )

          b-row
            // Approval Responsible
            b-col(md="6")
              b-form-group(:label="$t('label.investigation_approval_responsible')", :label-for="$t('label.investigation_approval_responsible')")
                v-select(
                  v-model="data.approvalResponsible"
                  label="title"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :disabled="readOnly || stages[data.stage] > 2"
                  :placeholder="$t('placeholder.investigation_approval_responsible')"
                )

            // Due date for approval responsible
            b-col(md="3")
              b-form-group(:label="$t('label.due_date')", :label-for="$t('label.due_date')")
                flat-pickr(
                  v-model="data.approvalDueDate"
                  :config="flatPickrConfig"
                  class="form-control"
                  :disabled="readOnly || stages[data.stage] > 2"
                )

          b-row
            // Verification Responsible
            b-col(md="6")
              b-form-group(:label="$t('label.verification_responsible')", :label-for="$t('label.verification_responsible')")
                v-select(
                  v-model="data.verificationResponsible"
                  label="title"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :disabled="readOnly || stages[data.stage] > 2"
                  :placeholder="$t('placeholder.verification_responsible')"
                )

            // Due date for verification responsible
            b-col(md="3")
              b-form-group(:label="$t('label.due_date')", :label-for="$t('label.due_date')")
                flat-pickr(
                  v-model="data.verificationDueDate"
                  :config="flatPickrConfig"
                  class="form-control"
                  :disabled="readOnly || stages[data.stage] > 2"
                )
          
          b-row(v-if="!data.isKarinLaw")
            // Author User Number
            b-col(md="4")
              b-form-group(:label="$t('label.author_user_number')", :label-for="$t('label.author_user_number')")
                b-form-input(
                  v-model="data.authorUserNumber",
                  trim,
                  :disabled="true"
                )
            
            // Affected User Number
            b-col(md="4")
              b-form-group(:label="$t('label.affected_user_number')", :label-for="$t('label.affected_user_number')")
                b-form-input(
                  v-model="data.affectedUserNumber",
                  trim,
                  :disabled="true"
                )
            
            // Reported User Number
            b-col(md="4")
              b-form-group(:label="$t('label.reported_user_number')", :label-for="$t('label.reported_user_number')")
                b-form-input(
                  v-model="data.reportedUserNumber",
                  trim,
                  :disabled="true"
                )

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.responsiblesTab", tab="responsiblesTab", :userType="userType", :clientId="clientId", @add-message="addMessage")

      // Analysis
      tab-content(v-if="readOnly || stages[data.stage] >= 3", :title="$t('message.investigation')", :before-change="() => validateTab(analysisTab, 'analysis')")
        validation-observer(ref="analysisTab", tag="form")
          // Problem
          b-row
            b-col(md="12")
              b-form-group(:label="$t('label.Problem')", :label-for="$t('label.Problem')")
                small.text-muted
                  | {{ $t('message.complaint_analysis') }}
                b-form-textarea(v-model="data.problemStatement", :disabled="readOnly || stages[data.stage] > 3 && allActionPlansBeyondAnalysis", :placeholder="$t('message.describe_complaint_analysis')")

          // 5 Why Methodology
          b-row
            b-col(md="12")
              b-form-group(label="" label-for="5WhyMethodology")
                b-form-checkbox(id="checkbox-1", v-model="fiveWhyMethodEnabled", name="checkbox-1", :value="true", :unchecked-value="false")
                  | {{ $t('label.WhyMethod') }}
          
          // 5 whys
          b-row(v-show="fiveWhyMethodEnabled")
            b-col(md="4")
              b-form-group(:label="$t('label.Why1')", :label-for="$t('label.Why1')")
                b-form-textarea(v-model="data.why1", :disabled="readOnly || stages[data.stage] > 3 && allActionPlansBeyondAnalysis", :placeholder="$t('placeholder.first_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why2')", :label-for="$t('label.Why2')")
                b-form-textarea(v-model="data.why2", :disabled="readOnly || stages[data.stage] > 3 && allActionPlansBeyondAnalysis", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why3')", :label-for="$t('label.Why3')")
                b-form-textarea(v-model="data.why3", :disabled="readOnly || stages[data.stage] > 3 && allActionPlansBeyondAnalysis", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why4')", :label-for="$t('label.Why4')")
                b-form-textarea(v-model="data.why4", :disabled="readOnly || stages[data.stage] > 3 && allActionPlansBeyondAnalysis", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why5')", :label-for="$t('label.Why5')")
                b-form-textarea(v-model="data.why5", :disabled="readOnly || stages[data.stage] > 3 && allActionPlansBeyondAnalysis", :placeholder="$t('placeholder.root_cause')")

          b-row(v-if="!readOnly")
            // Add action plan
            b-col(md="4")
              b-form-group(:label="$t('label.actionPlan')", :label-for="$t('label.actionPlan')")
                b-button(
                  type="button"
                  variant="success"
                  @click="addActionPlan('analysis')"
                  :disabled="!pacEnabled || stages[data.stage] > 3 && allActionPlansBeyondAnalysis"
                )
                  | {{ $t('message.addActionPlan') }}
                div(v-show="!pacEnabled && (stages[data.stage] <= 3 || !allActionPlansBeyondAnalysis)")
                  small(class="text-danger") {{ actionPlanType && fiveWhyMethodEnabled ? $t('message.5WhysMustBeComplete') : $t('message.youMustSelectActionPlanType') }}

            // Action plan type
            b-col(md="4")
              b-form-group(:label="$t('label.actionPlanType')", :label-for="$t('label.actionPlanType')")
                validation-provider(:name="$t('label.actionPlanType')", v-slot="{ errors }")
                  v-select(
                    v-model="actionPlanType"
                    label="title"
                    :options="actionPlanOptions"
                    :reduce="(state) => state.value"
                    :disabled="readOnly || stages[data.stage] > 3 && allActionPlansBeyondAnalysis"
                    :placeholder="$t('placeholder.actionPlanType')"
                  )
                  small(class="text-danger") {{ errors[0] }}
          
          // Title
          b-row(class="mt-1")
            b-col(md="12")
              h5(class="mb-0")
                | {{ $t('label.actionPlans') }}

          // No action plans message
          ul.list-group.mt-1.mb-2(v-if="!actionPlans.length")
            li(class="list-group-item text-center text-muted font-weight-bold bg-light")
              | {{ $t('message.no_action_plans') }}

          // Action plans list
          b-row(v-for="(item, index) in actionPlans" :key="index" :class="index % 2 === 0 ? '' : 'bg-light rounded py-1'")
            b-col(v-if="index % 2 === 0", md="12")
              hr(:class="index ? 'my-2' : 'mb-2'")

            b-col(md="3")
              b-form-group(:label="$t('label.actionPlanName')", :label-for="$t('label.actionPlanName')")
                validation-provider(:name="`${$t('label.actionPlanName')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  b-form-textarea(v-model="item.name", :disabled="readOnly || !!item._id && item.stage !== 'analysis'", :placeholder="$t('placeholder.name')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="3")
              b-form-group(:label="$t('label.Type')", :label-for="$t('label.Type')")
                validation-provider(:name="`${$t('label.Type')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  v-select(v-model="item.type", label="title", :options="actionPlanOptions", :reduce="(state) => state.value", :disabled="readOnly || !!item._id && item.stage !== 'analysis'", :placeholder="$t('placeholder.actionPlanType')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="6")
              b-form-group(:label="$t('label.description')", :label-for="$t('label.description')")
                b-form-textarea(v-model="item.description", :disabled="readOnly || !!item._id && item.stage !== 'analysis'", :placeholder="$t('placeholder.action_plan_description')")

            b-col(md="4")
              b-form-group(:label="$t('label.action_plan_responsible')", :label-for="$t('label.action_plan_responsible')")
                validation-provider(:name="`${$t('label.action_plan_responsible')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  v-select(
                    v-model="item.executionResponsible"
                    label="title"
                    :disabled="readOnly || !!item._id && item.stage !== 'analysis'"
                    :options="workersTotal"
                    :reduce="worker => worker.value"
                    :placeholder="$t('placeholder.action_plan_responsible')"
                  )
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="2")
              b-form-group(:label="$t(`label.${item.type === 'immediate' ? 'executionDate' : 'startDate'}`)", label-for="startDate")
                validation-provider(:name="`${$t(`label.${item.type === 'immediate' ? 'executionDate' : 'startDate'}`)} ${index + 1}`", rules="required", v-slot="{ errors }")
                  flat-pickr(
                    v-model="item.startDate"
                    :config="item.type === 'immediate' ? flatPickrConfigImmediatePAC : flatPickrConfig"
                    :disabled="readOnly || !!item._id && item.stage !== 'analysis'"
                    class="form-control"
                  )
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="item.type === 'in_term'", md="2")
              b-form-group(:label="$t('label.endDate')", :label-for="$t('label.endDate')")
                validation-provider(:name="`${$t('label.endDate')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  flat-pickr(
                    v-model="item.endDate"
                    :config="flatPickrConfig"
                    :disabled="readOnly || !!item._id && item.stage !== 'analysis'"
                    class="form-control"
                  )
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="item.type === 'immediate'", :md="item.evidence_key && item.evidence_key.length && !readOnly ? '2' : readOnly ? '6' : '4'")
              b-form-group(:label="$t('label.evidence')", :label-for="$t('label.evidence')")
                // Evidence key message
                span(v-if="readOnly")
                  | {{ formatKey(item.evidence_key) }}
                
                // Open Document
                b-button(
                  v-else-if="item.evidence_key && item.evidence_key.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  :disabled="readOnly"
                  @click="openDocument(item.evidence_key)"
                )
                  | {{ $t('message.open') }}

                // Upload Document
                b-button(
                  v-else
                  class="mr-1"
                  type="button"
                  variant="info"
                  :disabled="readOnly"
                  @click="activateEvidenceSelector(index)"
                )
                  | {{ $t('message.upload') }}
                small(v-if="item.evidenceFile && item.evidenceFile.length")
                  | {{ item.evidenceFile.length === 1 ? item.evidenceFile[0].name : `${item.evidenceFile.length} ${$t('evidences')}` }}
                small(v-else-if="!(item.evidence_key && item.evidence_key.length) && !readOnly", class="text-danger")
                  | {{ $t('message.upload_evidence_required') }}

            input(
              type="file"
              ref="evidenceSelector"
              style="display: none"
              :accept="inputFileTypes"
              multiple
              @change="changeEvidenceSelected"
            )

            b-col(v-if="!readOnly" md="2" class="mt-2")
              b-button(
                class="mr-1"
                type="button"
                variant="danger"
                :disabled="readOnly || !!item._id && item.stage !== 'analysis'"
                @click="deleteActionPlan(item._id, index)"
              )
                | {{ $t('message.delete') }}

            b-col(v-if="index % 2 === 0 && index < actionPlans.length - 1", md="12")
              hr(class="mb-2")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.analysisTab", tab="analysisTab", :userType="userType", :clientId="clientId", @add-message="addMessage")

      // Approval
      tab-content(v-if="readOnly || stages[data.stage] >= 4", :title="$t('message.approval')", :before-change="() => validateTab(approvalTab)")
        validation-observer(ref="approvalTab", tag="form")
          // Approve all action plans
          b-row
            b-col(v-if="!readOnly" :md="approveAllCommentEnabled ? '4' : '12'")
              b-form-group(:label="$t('label.approveAllActionPlans')", :label-for="$t('label.approveAllActionPlans')")
                b-button(
                  type="button"
                  variant="success"
                  @click="approveAllActionPlans"
                  :disabled="readOnly || !approveAllEnabled"
                )
                  | {{ $t('message.approveAll') }}
                small(v-if="!approveAllEnabled && data.stage === 'approval'", class="text-danger ml-1") {{ $t('message.mustSeeAllEvidences') }}
            b-col(v-if="approveAllCommentEnabled", :md="readOnly ? '12' : '8'")
              b-form-group(:label="$t('label.approvalComment')", :label-for="$t('label.approvalComment')")
                validation-provider(:name="$t('label.approvalComment')", :rules="data.stage === 'approval' && !readOnly ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="data.approvalComment", :disabled="readOnly || stages[data.stage] > 4", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}

          // Problem
          b-row
            b-col(md="12")
              b-form-group(
                :label="$t('label.Problem')",
                :label-for="$t('label.Problem')"
              )
                small.text-muted
                  | {{ $t('message.complaint_analysis') }}
                b-form-textarea(
                  v-model="data.problemStatement"
                  :disabled="true"
                  :placeholder="$t('message.describe_complaint_analysis')"
                )

          // 5 Why Methodology
          b-row
            b-col(md="12")
              b-form-group(label="" label-for="5WhyMethodology")
                b-form-checkbox(
                  id="checkbox-1"
                  v-model="fiveWhyMethodEnabled"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                )
                  | {{ $t('label.WhyMethod') }}
          
          // 5 whys
          b-row(v-show="fiveWhyMethodEnabled")
            b-col(md="4")
              b-form-group(:label="$t('label.Why1')", :label-for="$t('label.Why1')")
                b-form-textarea(v-model="data.why1", :disabled="true", :placeholder="$t('placeholder.first_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why2')", :label-for="$t('label.Why2')")
                b-form-textarea(v-model="data.why2", :disabled="true", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why3')", :label-for="$t('label.Why3')")
                b-form-textarea(v-model="data.why3", :disabled="true", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why4')", :label-for="$t('label.Why4')")
                b-form-textarea(v-model="data.why4", :disabled="true", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why5')", :label-for="$t('label.Why5')")
                b-form-textarea(v-model="data.why5", :disabled="true", :placeholder="$t('placeholder.root_cause')")
          
          // Title
          b-row(class="mt-1")
            b-col(md="12")
              h5(class="mb-0")
                | {{ $t('label.actionPlans') }}
          
          // No action plans message
          ul.list-group.mt-1.mb-2(v-if="!actionPlans.length")
            li(class="list-group-item text-center text-muted font-weight-bold bg-light")
              | {{ $t('message.no_action_plans') }}

          // Action plans list
          b-row(v-for="(item, index) in actionPlans", :key="index" :class="index % 2 === 0 ? '' : 'bg-light rounded py-1'")
            b-col(v-if="index % 2 === 0", md="12")
              hr(:class="index ? 'my-2' : 'mb-2'")

            b-col(md="3")
              b-form-group(:label="$t('label.actionPlanName')", :label-for="$t('label.actionPlanName')")
                b-form-textarea(v-model="item.name", :disabled="true", :placeholder="$t('placeholder.name')")

            b-col(md="3")
              b-form-group(:label="$t('label.Type')", :label-for="$t('label.Type')")
                v-select(v-model="item.type", label="title", :options="actionPlanOptions", :reduce="(state) => state.value", :disabled="true", :placeholder="$t('placeholder.actionPlanType')")
            
            b-col(md="6")
              b-form-group(:label="$t('label.description')", :label-for="$t('label.description')")
                b-form-textarea(v-model="item.description", :disabled="true", :placeholder="$t('placeholder.action_plan_description')")

            b-col(md="6")
              b-form-group(:label="$t('label.action_plan_responsible')", :label-for="$t('label.action_plan_responsible')")
                v-select(
                  v-model="item.executionResponsible"
                  label="title"
                  :disabled="true"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :placeholder="$t('placeholder.action_plan_responsible')"
                )

            b-col(md="3")
              b-form-group(:label="$t(`label.${item.type === 'immediate' ? 'executionDate' : 'startDate'}`)", label-for="startDate")
                flat-pickr(
                  v-model="item.startDate"
                  :config="flatPickrConfig"
                  :disabled="true"
                  class="form-control"
                )
              
            b-col(v-if="item.type === 'in_term'", md="3")
              b-form-group(:label="$t('label.endDate')", :label-for="$t('label.endDate')")
                flat-pickr(
                  v-model="item.endDate"
                  :config="flatPickrConfig"
                  :disabled="true"
                  class="form-control"
                )
            
            b-col(v-else, md="3")
              b-form-group(:label="$t('label.evidence')", :label-for="$t('label.evidence')")
                // Evidence key message
                span(v-if="readOnly")
                  | {{ formatKey(item.evidence_key) }}
                
                // Open Document
                b-button(
                  v-else-if="item.evidence_key && item.evidence_key.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  :disabled="readOnly"
                  @click="openDocument(item.evidence_key); addEvidenceSeen(item._id, 'approval')"
                )
                  | {{ $t('message.open') }}
          
            b-col(md="3")
              b-form-group(:label="$t('message.approval')", :label-for="$t('message.approval')")
                validation-provider(:name="`${$t('message.approval')} ${index + 1}`", :rules="item.stage === 'approval' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.approvalResult", :disabled="readOnly || item.stage !== 'approval'", label="title", :options="resultOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.result')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="3")
              b-form-group(:label="$t('label.nextStage')", :label-for="$t('label.nextStage')")
                validation-provider(:name="`${$t('label.nextStage')} ${index + 1}`", :rules="item.stage === 'approval' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.approvalNextStage", :disabled="readOnly || item.approvalResult !== 'rejected' || item.stage !== 'approval'", label="title", :options="item.type === 'immediate' ? correctiveStageOptions : inTermApprovalStageOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.nextStage')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="6")
              b-form-group(:label="$t('label.comment')", :label-for="$t('label.comment')")
                validation-provider(:name="`${$t('label.comment')} ${index + 1}`", :rules="item.stage === 'approval' && !approveAllCommentEnabled ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="item.approvalComment", :disabled="readOnly || item.stage !== 'approval'", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="index % 2 === 0 && index < actionPlans.length - 1", md="12")
              hr(class="mb-2")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.approvalTab", tab="approvalTab", :userType="userType", :clientId="clientId", @add-message="addMessage")
      
      // Corrective
      tab-content(v-if="readOnly || stages[data.stage] >= 5", :title="$t('message.corrective')", :before-change="() => validateTab(correctiveTab, 'corrective')")
        validation-observer(ref="correctiveTab", tag="form")
          // Title
          b-row
            b-col(md="12")
              h5
                | {{ readOnly ? $t('message.action_plans_being_executed') : $t('label.inTermActionPlans') }}

          // No action plans message
          ul.list-group.mt-1.mb-2(v-if="!actionPlans.length")
            li(class="list-group-item text-center text-muted font-weight-bold bg-light")
              | {{ $t('message.no_action_plans') }}

          // Action plans list
          b-row(v-if="(item.executionResponsible === userData.worker_id.$oid) && item.type === 'in_term'", v-for="(item, index) in actionPlans", :key="index" :class="index % 2 === 0 ? '' : 'bg-light rounded py-1'")
            b-col(v-if="index % 2 === 0", md="12")
              hr(:class="index ? 'my-2' : 'mb-2'")

            b-col(md="3")
              b-form-group(:label="$t('label.actionPlanName')", :label-for="$t('label.actionPlanName')")
                b-form-textarea(v-model="item.name", :disabled="true", :placeholder="$t('placeholder.name')")

            b-col(md="5")
              b-form-group(:label="$t('label.description')", :label-for="$t('label.description')")
                b-form-textarea(v-model="item.description", :disabled="true", :placeholder="$t('placeholder.action_plan_description')")

            b-col(md="4")
              b-form-group(:label="$t('label.action_plan_responsible')", :label-for="$t('label.action_plan_responsible')")
                v-select(
                  v-model="item.executionResponsible"
                  label="title"
                  :disabled="true"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :placeholder="$t('placeholder.action_plan_responsible')"
                )

            b-col(md="2")
              b-form-group(:label="$t('label.executionDate')", label-for="executionDate")
                validation-provider(:name="`${$t('label.executionDate')} ${index + 1}`", :rules="item.stage === 'corrective' ? 'required' : ''", v-slot="{ errors }")
                  flat-pickr(
                    v-model="item.executionDate"
                    :config="flatPickrConfig"
                    :disabled="readOnly || item.stage !== 'corrective'"
                    class="form-control"
                  )
                  small(class="text-danger") {{ errors[0] }}
            
            b-col(md="3")
              b-form-group(:label="$t('label.result')", :label-for="$t('label.result')")
                validation-provider(:name="`${$t('label.result')} ${index + 1}`", :rules="item.stage === 'corrective' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.correctiveResult", :disabled="readOnly || item.stage !== 'corrective'", label="title", :options="resultOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.result')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="3")
              b-form-group(:label="$t('label.nextStage')", :label-for="$t('label.nextStage')")
                validation-provider(:name="`${$t('label.nextStage')} ${index + 1}`", :rules="item.stage === 'corrective' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.correctiveNextStage", :disabled="readOnly || item.correctiveResult !== 'rejected' || item.stage !== 'corrective'", label="title", :options="correctiveStageOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.nextStage')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="4")
              b-form-group(:label="$t('label.evidence')", :label-for="$t('label.evidence')")
                // Evidence key message
                span(v-if="readOnly")
                  | {{ formatKey(item.evidence_key) }}
                
                // Open Document
                b-button(
                  v-else-if="item.evidence_key && item.evidence_key.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  :disabled="readOnly"
                  @click="openDocument(item.evidence_key)"
                )
                  | {{ $t('message.open') }}

                // Upload Document
                b-button(
                  v-else
                  class="mr-1"
                  type="button"
                  variant="info"
                  :disabled="readOnly || item.stage !== 'corrective'"
                  @click="activateEvidenceSelector(index)"
                )
                  | {{ $t('message.upload') }}
                small(v-if="item.evidenceFile && item.evidenceFile.length")
                  | {{ item.evidenceFile.length === 1 ? item.evidenceFile[0].name : `${item.evidenceFile.length} ${$t('evidences')}` }}
                small(v-else-if="!(item.evidence_key && item.evidence_key.length) && !readOnly && item.stage === 'corrective' && item.correctiveNextStage === 'verification'", class="text-danger")
                  | {{ $t('message.upload_evidence_required') }}

            b-col(md="12")
              b-form-group(:label="$t('label.comment')", :label-for="$t('label.comment')")
                validation-provider(:name="`${$t('label.comment')} ${index + 1}`", :rules="item.stage === 'corrective' ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="item.correctiveComment", :disabled="readOnly || item.stage !== 'corrective'", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="index % 2 === 0 && index < actionPlans.length - 1", md="12")
              hr(class="mb-2")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.correctiveTab", tab="correctiveTab", :userType="userType", :clientId="clientId", @add-message="addMessage")

      // Verification
      tab-content(v-if="readOnly || stages[data.stage] >= 6", :title="$t('message.verification')", :before-change="() => validateTab(verificationTab)")
        validation-observer(ref="verificationTab", tag="form")
          // Verify all action plans
          b-row
            b-col(v-if="!readOnly" :md="verifyAllCommentEnabled ? '4' : '12'")
              b-form-group(:label="$t('label.verifyAllActionPlans')", :label-for="$t('label.verifyAllActionPlans')")
                b-button(
                  type="button"
                  variant="success"
                  @click="verifyAllActionPlans"
                  :disabled="readOnly || !verifyAllEnabled"
                )
                  | {{ $t('message.verifyAll') }}
                small(v-if="!verifyAllEnabled && data.stage === 'verification'", class="text-danger ml-1") {{ $t('message.mustSeeAllEvidences') }}
            b-col(v-if="verifyAllCommentEnabled", :md="readOnly ? '12' : '8'")
              b-form-group(:label="$t('label.verificationComment')", :label-for="$t('label.verificationComment')")
                validation-provider(:name="$t('label.verificationComment')", :rules="data.stage === 'verification' && !readOnly ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="data.verificationComment", :disabled="readOnly || stages[data.stage] > 6", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}
          
          // Title
          b-row(class="mt-1")
            b-col(md="12")
              h5(class="mb-0")
                | {{ $t('label.actionPlans') }}

          // No action plans message
          ul.list-group.mt-1.mb-2(v-if="!actionPlans.length")
            li(class="list-group-item text-center text-muted font-weight-bold bg-light")
              | {{ $t('message.no_action_plans') }}

          // Action plans list
          b-row(v-for="(item, index) in actionPlans", :key="index" :class="index % 2 === 0 ? '' : 'bg-light rounded py-1'")
            b-col(v-if="index % 2 === 0", md="12")
              hr(:class="index ? 'my-2' : 'mb-2'")

            b-col(md="3")
              b-form-group(:label="$t('label.actionPlanName')", :label-for="$t('label.actionPlanName')")
                b-form-textarea(v-model="item.name", :disabled="true", :placeholder="$t('placeholder.name')")

            b-col(md="5")
              b-form-group(:label="$t('label.description')", :label-for="$t('label.description')")
                b-form-textarea(v-model="item.description", :disabled="true", :placeholder="$t('placeholder.action_plan_description')")

            b-col(md="4")
              b-form-group(:label="$t('label.action_plan_responsible')", :label-for="$t('label.action_plan_responsible')")
                v-select(
                  v-model="item.executionResponsible"
                  label="title"
                  :disabled="true"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :placeholder="$t('placeholder.action_plan_responsible')"
                )

            b-col(md="2")
              b-form-group(:label="$t('label.executionDate')", label-for="executionDate")
                flat-pickr(
                  v-model="item.executionDate"
                  :config="flatPickrConfig"
                  :disabled="true"
                  class="form-control"
                )
            
            b-col(:md="item.type === 'in_term' ? '2' : '10'")
              b-form-group(:label="$t('label.evidence')", :label-for="$t('label.evidence')")
                // Evidence key message
                span(v-if="readOnly")
                  | {{ formatKey(item.evidence_key) }}
                
                // Open Document
                b-button(
                  v-else-if="item.evidence_key && item.evidence_key.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  :disabled="readOnly"
                  @click="openDocument(item.evidence_key); addEvidenceSeen(item._id, 'verification')"
                )
                  | {{ $t('message.open') }}
            
            b-col(v-if="item.type === 'in_term'", md="3")
              b-form-group(:label="$t('label.correctiveResult')", :label-for="$t('label.correctiveResult')")
                v-select(v-model="item.correctiveResult", :disabled="true", label="title", :options="resultOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.result')")

            b-col(v-if="item.type === 'in_term'", md="5")
              b-form-group(:label="$t('label.correctiveComment')", :label-for="$t('label.correctiveComment')")
                b-form-textarea(v-model="item.correctiveComment", :disabled="true", :placeholder="$t('placeholder.comment')")

            b-col(md="3")
              b-form-group(:label="$t('message.verification')", :label-for="$t('message.verification')")
                validation-provider(:name="`${$t('message.verification')} ${index + 1}`", :rules="item.stage === 'verification' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.verificationResult", :disabled="readOnly || item.stage !== 'verification'", label="title", :options="resultOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.result')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="3")
              b-form-group(:label="$t('label.nextStage')", :label-for="$t('label.nextStage')")
                validation-provider(:name="`${$t('label.nextStage')} ${index + 1}`", :rules="item.stage === 'verification' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.verificationNextStage", :disabled="readOnly || item.verificationResult !== 'rejected' || item.stage !== 'verification'", label="title", :options="item.type === 'immediate' ? immediateVerificationStageOptions : inTermVerificationStageOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.nextStage')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="6")
              b-form-group(:label="$t('label.comment')", :label-for="$t('label.comment')")
                validation-provider(:name="`${$t('label.comment')} ${index + 1}`", :rules="item.stage === 'verification' && !verifyAllCommentEnabled ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="item.verificationComment", :disabled="readOnly || item.stage !== 'verification'", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="index % 2 === 0 && index < actionPlans.length - 1", md="12")
              hr(class="mb-2")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.verificationTab", tab="verificationTab", :userType="userType", :clientId="clientId", @add-message="addMessage")

      // Corrective Measures
      tab-content(v-if="readOnly || stages[data.stage] >= 7", :title="$t('message.correctiveMeasures')", :before-change="() => validateTab(correctiveMeasuresTab, 'correctiveMeasures')")
        validation-observer(ref="correctiveMeasuresTab", tag="form")
          // Report Conclusion
          b-row
            b-col(md="12")
              b-form-group(:label="$t('label.reportConclusion')", :label-for="$t('label.reportConclusion')")
                b-form-textarea(v-model="data.reportConclusion", :disabled="readOnly || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures", :placeholder="$t('message.describe_report_conclusion')")

          // 5 Why Methodology
          b-row
            b-col(md="12")
              b-form-group(label="" label-for="5WhyMethodology")
                b-form-checkbox(id="checkbox-1", v-model="fiveWhyMethodEnabled", name="checkbox-1", :value="true", :unchecked-value="false")
                  | {{ $t('label.WhyMethod') }}
          
          // 5 whys
          b-row(v-show="fiveWhyMethodEnabled")
            b-col(md="4")
              b-form-group(:label="$t('label.Why1')", :label-for="$t('label.Why1')")
                b-form-textarea(v-model="data.why6", :disabled="readOnly || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures", :placeholder="$t('placeholder.first_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why2')", :label-for="$t('label.Why2')")
                b-form-textarea(v-model="data.why7", :disabled="readOnly || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why3')", :label-for="$t('label.Why3')")
                b-form-textarea(v-model="data.why8", :disabled="readOnly || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why4')", :label-for="$t('label.Why4')")
                b-form-textarea(v-model="data.why9", :disabled="readOnly || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures", :placeholder="$t('placeholder.inner_cause')")
            b-col(md="4")
              b-form-group(:label="$t('label.Why5')", :label-for="$t('label.Why5')")
                b-form-textarea(v-model="data.why10", :disabled="readOnly || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures", :placeholder="$t('placeholder.root_cause')")

          b-row(v-if="!readOnly")
            // Add action plan
            b-col(md="4")
              b-form-group(:label="$t('label.actionPlan')", :label-for="$t('label.actionPlan')")
                b-button(
                  type="button"
                  variant="success"
                  @click="addActionPlan('correctiveMeasures')"
                  :disabled="!pacEnabledCorrectiveMeasures || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures"
                )
                  | {{ $t('message.addActionPlan') }}
                div(v-show="!pacEnabledCorrectiveMeasures && (stages[data.stage] <= 7 || !allActionPlansBeyondCorrectiveMeasures)")
                  small(class="text-danger") {{ actionPlanTypeCorrectiveMeasures && fiveWhyMethodEnabled ? $t('message.5WhysMustBeComplete') : $t('message.youMustSelectActionPlanType') }}

            // Action plan type
            b-col(md="4")
              b-form-group(:label="$t('label.actionPlanType')", :label-for="$t('label.actionPlanType')")
                validation-provider(:name="$t('label.actionPlanType')", v-slot="{ errors }")
                  v-select(
                    v-model="actionPlanTypeCorrectiveMeasures"
                    label="title"
                    :options="actionPlanOptions"
                    :reduce="(state) => state.value"
                    :disabled="readOnly || stages[data.stage] > 7 && allActionPlansBeyondCorrectiveMeasures"
                    :placeholder="$t('placeholder.actionPlanType')"
                  )
                  small(class="text-danger") {{ errors[0] }}
          
          // Title
          b-row(class="mt-1")
            b-col(md="12")
              h5(class="mb-0")
                | {{ $t('label.actionPlans') }}

          // No action plans message
          ul.list-group.mt-1.mb-2(v-if="!actionPlans.length")
            li(class="list-group-item text-center text-muted font-weight-bold bg-light")
              | {{ $t('message.no_action_plans') }}

          // Action plans list
          b-row(v-for="(item, index) in actionPlans" :key="index" :class="index % 2 === 0 ? '' : 'bg-light rounded py-1'")
            b-col(v-if="index % 2 === 0", md="12")
              hr(:class="index ? 'my-2' : 'mb-2'")

            b-col(md="3")
              b-form-group(:label="$t('label.actionPlanName')", :label-for="$t('label.actionPlanName')")
                validation-provider(:name="`${$t('label.actionPlanName')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  b-form-textarea(v-model="item.name", :disabled="readOnly || !!item._id && item.stage !== 'correctiveMeasures'", :placeholder="$t('placeholder.name')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="3")
              b-form-group(:label="$t('label.Type')", :label-for="$t('label.Type')")
                validation-provider(:name="`${$t('label.Type')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  v-select(v-model="item.type", label="title", :options="actionPlanOptions", :reduce="(state) => state.value", :disabled="readOnly || !!item._id && item.stage !== 'correctiveMeasures'", :placeholder="$t('placeholder.actionPlanType')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="6")
              b-form-group(:label="$t('label.description')", :label-for="$t('label.description')")
                b-form-textarea(v-model="item.description", :disabled="readOnly || !!item._id && item.stage !== 'correctiveMeasures'", :placeholder="$t('placeholder.action_plan_description')")

            b-col(md="4")
              b-form-group(:label="$t('label.action_plan_responsible')", :label-for="$t('label.action_plan_responsible')")
                validation-provider(:name="`${$t('label.action_plan_responsible')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  v-select(
                    v-model="item.executionResponsible"
                    label="title"
                    :disabled="readOnly || !!item._id && item.stage !== 'correctiveMeasures'"
                    :options="workersTotal"
                    :reduce="worker => worker.value"
                    :placeholder="$t('placeholder.action_plan_responsible')"
                  )
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="2")
              b-form-group(:label="$t(`label.${item.type === 'immediate' ? 'executionDate' : 'startDate'}`)", label-for="startDate")
                validation-provider(:name="`${$t(`label.${item.type === 'immediate' ? 'executionDate' : 'startDate'}`)} ${index + 1}`", rules="required", v-slot="{ errors }")
                  flat-pickr(
                    v-model="item.startDate"
                    :config="item.type === 'immediate' ? flatPickrConfigImmediatePAC : flatPickrConfig"
                    :disabled="readOnly || !!item._id && item.stage !== 'correctiveMeasures'"
                    class="form-control"
                  )
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="item.type === 'in_term'", md="2")
              b-form-group(:label="$t('label.endDate')", :label-for="$t('label.endDate')")
                validation-provider(:name="`${$t('label.endDate')} ${index + 1}`", rules="required", v-slot="{ errors }")
                  flat-pickr(
                    v-model="item.endDate"
                    :config="flatPickrConfig"
                    :disabled="readOnly || !!item._id && item.stage !== 'correctiveMeasures'"
                    class="form-control"
                  )
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="item.type === 'immediate'", :md="item.evidence_key && item.evidence_key.length && !readOnly ? '2' : readOnly ? '6' : '4'")
              b-form-group(:label="$t('label.evidence')", :label-for="$t('label.evidence')")
                // Evidence key message
                span(v-if="readOnly")
                  | {{ formatKey(item.evidence_key) }}
                
                // Open Document
                b-button(
                  v-else-if="item.evidence_key && item.evidence_key.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  :disabled="readOnly"
                  @click="openDocument(item.evidence_key)"
                )
                  | {{ $t('message.open') }}

                // Upload Document
                b-button(
                  v-else
                  class="mr-1"
                  type="button"
                  variant="info"
                  :disabled="readOnly"
                  @click="activateEvidenceSelector(index)"
                )
                  | {{ $t('message.upload') }}
                small(v-if="item.evidenceFile && item.evidenceFile.length")
                  | {{ item.evidenceFile.length === 1 ? item.evidenceFile[0].name : `${item.evidenceFile.length} ${$t('evidences')}` }}
                small(v-else-if="!(item.evidence_key && item.evidence_key.length) && !readOnly", class="text-danger")
                  | {{ $t('message.upload_evidence_required') }}

            b-col(v-if="!readOnly" md="2" class="mt-2")
              b-button(
                class="mr-1"
                type="button"
                variant="danger"
                :disabled="readOnly || !!item._id && item.stage !== 'correctiveMeasures'"
                @click="deleteActionPlan(item._id, index)"
              )
                | {{ $t('message.delete') }}

            b-col(v-if="index % 2 === 0 && index < actionPlans.length - 1", md="12")
              hr(class="mb-2")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.correctiveMeasuresTab", tab="correctiveMeasuresTab", :userType="userType", :clientId="clientId", @add-message="addMessage")

      // Implementation
      tab-content(v-if="readOnly || stages[data.stage] >= 8", :title="$t('message.implementation')", :before-change="() => validateTab(implementationTab, 'implementation')")
        validation-observer(ref="implementationTab", tag="form")
          // Title
          b-row
            b-col(md="12")
              h5
                | {{ readOnly ? $t('message.action_plans_being_executed') : $t('label.inTermActionPlans') }}

          // No action plans message
          ul.list-group.mt-1.mb-2(v-if="!actionPlans.length")
            li(class="list-group-item text-center text-muted font-weight-bold bg-light")
              | {{ $t('message.no_action_plans') }}

          // Action plans list
          b-row(v-if="(item.executionResponsible === userData.worker_id.$oid) && item.type === 'in_term'", v-for="(item, index) in actionPlans", :key="index" :class="index % 2 === 0 ? '' : 'bg-light rounded py-1'")
            b-col(v-if="index % 2 === 0", md="12")
              hr(:class="index ? 'my-2' : 'mb-2'")

            b-col(md="3")
              b-form-group(:label="$t('label.actionPlanName')", :label-for="$t('label.actionPlanName')")
                b-form-textarea(v-model="item.name", :disabled="true", :placeholder="$t('placeholder.name')")

            b-col(md="5")
              b-form-group(:label="$t('label.description')", :label-for="$t('label.description')")
                b-form-textarea(v-model="item.description", :disabled="true", :placeholder="$t('placeholder.action_plan_description')")

            b-col(md="4")
              b-form-group(:label="$t('label.action_plan_responsible')", :label-for="$t('label.action_plan_responsible')")
                v-select(
                  v-model="item.executionResponsible"
                  label="title"
                  :disabled="true"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :placeholder="$t('placeholder.action_plan_responsible')"
                )

            b-col(md="2")
              b-form-group(:label="$t('label.executionDate')", label-for="executionDate")
                validation-provider(:name="`${$t('label.executionDate')} ${index + 1}`", :rules="item.stage === 'implementation' ? 'required' : ''", v-slot="{ errors }")
                  flat-pickr(
                    v-model="item.executionDate"
                    :config="flatPickrConfig"
                    :disabled="readOnly || item.stage !== 'implementation'"
                    class="form-control"
                  )
                  small(class="text-danger") {{ errors[0] }}
            
            b-col(md="3")
              b-form-group(:label="$t('label.result')", :label-for="$t('label.result')")
                validation-provider(:name="`${$t('label.result')} ${index + 1}`", :rules="item.stage === 'implementation' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.implementationResult", :disabled="readOnly || item.stage !== 'implementation'", label="title", :options="resultOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.result')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="3")
              b-form-group(:label="$t('label.nextStage')", :label-for="$t('label.nextStage')")
                validation-provider(:name="`${$t('label.nextStage')} ${index + 1}`", :rules="item.stage === 'implementation' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.implementationNextStage", :disabled="readOnly || item.implementationResult !== 'rejected' || item.stage !== 'implementation'", label="title", :options="implementationStageOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.nextStage')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="4")
              b-form-group(:label="$t('label.evidence')", :label-for="$t('label.evidence')")
                // Evidence key message
                span(v-if="readOnly")
                  | {{ formatKey(item.evidence_key) }}
                
                // Open Document
                b-button(
                  v-else-if="item.evidence_key && item.evidence_key.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  :disabled="readOnly"
                  @click="openDocument(item.evidence_key)"
                )
                  | {{ $t('message.open') }}

                // Upload Document
                b-button(
                  v-else
                  class="mr-1"
                  type="button"
                  variant="info"
                  :disabled="readOnly || item.stage !== 'implementation'"
                  @click="activateEvidenceSelector(index)"
                )
                  | {{ $t('message.upload') }}
                small(v-if="item.evidenceFile && item.evidenceFile.length")
                  | {{ item.evidenceFile.length === 1 ? item.evidenceFile[0].name : `${item.evidenceFile.length} ${$t('evidences')}` }}
                small(v-else-if="!(item.evidence_key && item.evidence_key.length) && !readOnly && item.stage === 'implementation' && item.implementationNextStage === 'finalized'", class="text-danger")
                  | {{ $t('message.upload_evidence_required') }}

            b-col(md="12")
              b-form-group(:label="$t('label.comment')", :label-for="$t('label.comment')")
                validation-provider(:name="`${$t('label.comment')} ${index + 1}`", :rules="item.stage === 'implementation' ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="item.implementationComment", :disabled="readOnly || item.stage !== 'implementation'", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="index % 2 === 0 && index < actionPlans.length - 1", md="12")
              hr(class="mb-2")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.correctiveTab", tab="implementationTab", :userType="userType", :clientId="clientId", @add-message="addMessage")

      // Finalized
      tab-content(v-if="readOnly || stages[data.stage] >= 9", :title="$t('message.finalized')", :before-change="() => validateTab(finalizedTab)")
        validation-observer(ref="finalizedTab", tag="form")
          // Finalize all action plans
          b-row
            b-col(v-if="!readOnly" :md="finalizeAllCommentEnabled ? '4' : '12'")
              b-form-group(:label="$t('label.finalizeAllActionPlans')", :label-for="$t('label.finalizeAllActionPlans')")
                b-button(
                  type="button"
                  variant="success"
                  @click="finalizeAllActionPlans"
                  :disabled="readOnly || !finalizeAllEnabled"
                )
                  | {{ $t('message.finalizeAll') }}
                small(v-if="!finalizeAllEnabled && data.stage === 'finalized'", class="text-danger ml-1") {{ $t('message.mustSeeAllEvidences') }}
            b-col(v-if="finalizeAllCommentEnabled", :md="readOnly ? '12' : '8'")
              b-form-group(:label="$t('label.finalizedComment')", :label-for="$t('label.finalizedComment')")
                validation-provider(:name="$t('label.finalizedComment')", :rules="data.stage === 'finalized' && !readOnly ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="data.finalizedComment", :disabled="readOnly || data.stage === 'end'", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}
          
          // Title
          b-row(class="mt-1")
            b-col(md="12")
              h5(class="mb-0")
                | {{ $t('label.actionPlans') }}

          // No action plans message
          ul.list-group.mt-1.mb-2(v-if="!actionPlans.length")
            li(class="list-group-item text-center text-muted font-weight-bold bg-light")
              | {{ $t('message.no_action_plans') }}

          // Action plans list
          b-row(v-for="(item, index) in actionPlans", :key="index" :class="index % 2 === 0 ? '' : 'bg-light rounded py-1'")
            b-col(v-if="index % 2 === 0", md="12")
              hr(:class="index ? 'my-2' : 'mb-2'")

            b-col(md="3")
              b-form-group(:label="$t('label.actionPlanName')", :label-for="$t('label.actionPlanName')")
                b-form-textarea(v-model="item.name", :disabled="true", :placeholder="$t('placeholder.name')")

            b-col(md="5")
              b-form-group(:label="$t('label.description')", :label-for="$t('label.description')")
                b-form-textarea(v-model="item.description", :disabled="true", :placeholder="$t('placeholder.action_plan_description')")

            b-col(md="4")
              b-form-group(:label="$t('label.action_plan_responsible')", :label-for="$t('label.action_plan_responsible')")
                v-select(
                  v-model="item.executionResponsible"
                  label="title"
                  :disabled="true"
                  :options="workersTotal"
                  :reduce="worker => worker.value"
                  :placeholder="$t('placeholder.action_plan_responsible')"
                )

            b-col(md="2")
              b-form-group(:label="$t('label.executionDate')", label-for="executionDate")
                flat-pickr(
                  v-model="item.executionDate"
                  :config="flatPickrConfig"
                  :disabled="true"
                  class="form-control"
                )
            
            b-col(:md="item.type === 'in_term' ? '2' : '10'")
              b-form-group(:label="$t('label.evidence')", :label-for="$t('label.evidence')")
                // Evidence key message
                span(v-if="readOnly")
                  | {{ formatKey(item.evidence_key) }}
                
                // Open Document
                b-button(
                  v-else-if="item.evidence_key && item.evidence_key.length"
                  class="mr-1"
                  type="button"
                  variant="success"
                  :disabled="readOnly"
                  @click="openDocument(item.evidence_key); addEvidenceSeen(item._id, 'finalized')"
                )
                  | {{ $t('message.open') }}
            
            b-col(v-if="item.type === 'in_term'", md="3")
              b-form-group(:label="$t('label.implementationResult')", :label-for="$t('label.implementationResult')")
                v-select(v-model="item.implementationResult", :disabled="true", label="title", :options="resultOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.result')")

            b-col(v-if="item.type === 'in_term'", md="5")
              b-form-group(:label="$t('label.implementationComment')", :label-for="$t('label.implementationComment')")
                b-form-textarea(v-model="item.implementationComment", :disabled="true", :placeholder="$t('placeholder.comment')")

            b-col(md="3")
              b-form-group(:label="$t('message.finalized')", :label-for="$t('message.finalized')")
                validation-provider(:name="`${$t('message.finalized')} ${index + 1}`", :rules="item.stage === 'finalized' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.finalizedResult", :disabled="readOnly || item.stage !== 'finalized'", label="title", :options="resultOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.result')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="3")
              b-form-group(:label="$t('label.nextStage')", :label-for="$t('label.nextStage')")
                validation-provider(:name="`${$t('label.nextStage')} ${index + 1}`", :rules="item.stage === 'finalized' ? 'required' : ''", v-slot="{ errors }")
                  v-select(v-model="item.finalizedNextStage", :disabled="readOnly || item.finalizedResult !== 'rejected' || item.stage !== 'finalized'", label="title", :options="item.type === 'immediate' ? immediateFinalizedStageOptions : inTermFinalizedStageOptions", :reduce="(state) => state.value", :placeholder="$t('placeholder.nextStage')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(md="6")
              b-form-group(:label="$t('label.comment')", :label-for="$t('label.comment')")
                validation-provider(:name="`${$t('label.comment')} ${index + 1}`", :rules="item.stage === 'finalized' && !finalizeAllCommentEnabled ? 'required' : ''", v-slot="{ errors }")
                  b-form-textarea(v-model="item.finalizedComment", :disabled="readOnly || item.stage !== 'finalized'", :placeholder="$t('placeholder.comment')")
                  small(class="text-danger") {{ errors[0] }}

            b-col(v-if="index % 2 === 0 && index < actionPlans.length - 1", md="12")
              hr(class="mb-2")

          hr(class="mb-2")

          // Messages
          messages(:messages="tabMessages.finalizedTab", tab="finalizedTab", :userType="userType", :clientId="clientId", @add-message="addMessage")
</template>

<script>
import vSelect from 'vue-select'
import { onMounted, ref, computed, watch } from '@vue/composition-api/dist/vue-composition-api'
import useCommon from '@/views/organization/useCommon'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations"
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { useRouter } from '@core/utils/utils'
import { stateOptions, actionPlanOptions, resultOptions, inTermApprovalStageOptions, correctiveStageOptions, immediateVerificationStageOptions, inTermVerificationStageOptions, implementationStageOptions, immediateFinalizedStageOptions, inTermFinalizedStageOptions, flatPickrConfig, inputFileTypes } from '@/constants'
import { FormWizard, TabContent } from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { BFormDatepicker, BFormCheckbox } from "bootstrap-vue"
import awsConnection from '@/views/habit/aws'
import { createActionPlan, updateActionPlan } from '@/@core/queries/action-plans'
import axios from '@axios'
import Messages from './Messages.vue'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'

export default {
  name: 'Form',
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
    FormWizard,
    TabContent,
    BFormDatepicker,
    BFormCheckbox,
    Messages,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    updateActiveTab: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: String,
      default: 'manager',
    },
    clientId: {
      type: String,
      default: '',
    }
  },
  setup(props, { emit }) {
    const { showErrorMessage } = useNotifications()
    const { getWorkersTotalForDropDown, workersTotal, getImprovementsForDropDown, improvements, getLocationsForDropDown, locations, openDocument, formatDate } = useCommon()
    const { router } = useRouter()
    const { singleUpload } = awsConnection()
    const { sendEmailActionPlan } = useCommonTodo()
    const form = ref(null)
    const evidenceSelector = ref(null)
    const evidenceIndex = ref(null)
    const noDocumentMessage = computed(() => i18n.t('message.no_document_attached'))
    const identificationTab = ref(null)
    const responsiblesTab = ref(null)
    const analysisTab = ref(null)
    const approvalTab = ref(null)
    const correctiveTab = ref(null)
    const verificationTab = ref(null)
    const correctiveMeasuresTab = ref(null)
    const implementationTab = ref(null)
    const finalizedTab = ref(null)
    const actionPlanType = ref(null)
    const actionPlanTypeCorrectiveMeasures = ref(null)
    const fiveWhyMethodEnabled = ref(false)
    const pacEnabled = computed(() => actionPlanType.value && (!fiveWhyMethodEnabled.value || props.data.why1 && props.data.why2 && props.data.why3 && props.data.why4 && props.data.why5))
    const pacEnabledCorrectiveMeasures = computed(() => actionPlanTypeCorrectiveMeasures.value && (!fiveWhyMethodEnabled.value || props.data.why6 && props.data.why7 && props.data.why8 && props.data.why9 && props.data.why10))
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    const actionPlans = ref([])
    const actionPlansToDelete = []
    const approveAllEnabled = ref(false)
    const verifyAllEnabled = ref(false)
    const finalizeAllEnabled = ref(false)
    const approveAllCommentEnabled = ref(false)
    const verifyAllCommentEnabled = ref(false)
    const finalizeAllCommentEnabled = ref(false)
    const actionPlansToApprove = ref([])
    const actionPlansToVerify = ref([])
    const actionPlansToFinalize = ref([])
    let isCompletedOverdue = false
    const stages = { registration: 1, analysis: 3, approval: 4, corrective: 5, verification: 6, correctiveMeasures: 7, implementation: 8, finalized: 9, end: 10 }
    const complaintForm = ref(null)
    const updateActiveTab = ref(false)
    const tabMessages = ref({
      identificationTab: [],
      responsiblesTab: [],
      analysisTab: [],
      approvalTab: [],
      correctiveTab: [],
      verificationTab: [],
      correctiveMeasuresTab: [],
      implementationTab: [],
      finalizedTab: [],
    })
    const isTabMessagesPopulated = ref(false)
    const allActionPlansBeyondAnalysis = computed(() => actionPlans.value.every(e => e.stage !== "analysis"))
    const allActionPlansBeyondCorrectiveMeasures = computed(() => actionPlans.value.every(e => e.stage !== "correctiveMeasures"))
    const now = new Date()
    const endOfToday = new Date()
    endOfToday.setHours(23, 59, 59, 999)
    const flatPickrConfigImmediatePAC = { dateFormat: 'd-m-Y', maxDate: endOfToday, locale: Spanish }
    const destinationFolder = `${userData.client.$oid}/documents`

    const addMessage = (message) => {
      // Add message to the tab messages array
      tabMessages.value[message.tab].push(message)

      // Update complaint with new message
      emit('addNewMessage', message)
    }

    watch(props, () => {
      updateActiveTab.value = props.updateActiveTab
      actionPlans.value = props.data.actionPlans || []
      actionPlansToApprove.value = actionPlans.value.filter(e => e.stage === 'approval')
      actionPlansToVerify.value = actionPlans.value.filter(e => e.stage === 'verification')
      actionPlansToFinalize.value = actionPlans.value.filter(e => e.stage === 'finalized')
      if (stages[props.data.stage] > 4) approveAllCommentEnabled.value = true
      if (stages[props.data.stage] > 6) verifyAllCommentEnabled.value = true
      if (props.data.stage === 'end') finalizeAllCommentEnabled.value = true
      if (props.data.messages?.length && !isTabMessagesPopulated.value) {
        // Update this flag to populate messages only once
        isTabMessagesPopulated.value = true

        // Distribute messages to their respective tabs
        props.data.messages.forEach(message => {
          if (tabMessages.value[message.tab]) {
            message.createdAt = new Date(message.createdAt)
            tabMessages.value[message.tab].push(message)
          }
        })
      }
    })

    watch(updateActiveTab, () => {
      if (complaintForm.value && stages[props.data.stage]) {
        const activeTabIndex = Math.min(stages[props.data.stage] - 1, complaintForm.value.tabCount - 1)
        complaintForm.value.activateTab(activeTabIndex)
        complaintForm.value.activeTabIndex = activeTabIndex
        complaintForm.value.maxStep = Math.max(activeTabIndex, 1)
        complaintForm.value.tabs.forEach((t, i) => t.checked = i <= Math.max(activeTabIndex, 1))
      }
    })

    watch(actionPlans, () => {
      actionPlans.value.forEach(e => {
        if (e.stage === 'approval' && e.approvalResult === 'approved') e.approvalNextStage = e.type === 'immediate' ? 'verification' : 'corrective'
        if (e.stage === 'corrective' && e.correctiveResult === 'approved') e.correctiveNextStage = 'verification'
        if (e.stage === 'verification' && e.verificationResult === 'approved') e.verificationNextStage = 'correctiveMeasures'
        if (e.stage === 'implementation' && e.implementationResult === 'approved') e.implementationNextStage = 'finalized'
        if (e.stage === 'finalized' && e.finalizedResult === 'approved') e.finalizedNextStage = 'end'
      })
    }, { deep: true })

    watch(actionPlansToApprove, () => {
      if (actionPlansToApprove.value.length && actionPlansToApprove.value.every(e => e.evidenceSeen || e.type === 'in_term')) {
        approveAllEnabled.value = true
      }
    })

    watch(actionPlansToVerify, () => {
      if (actionPlansToVerify.value.length && actionPlansToVerify.value.every(e => e.evidenceSeen)) {
        verifyAllEnabled.value = true
      }
    })

    watch(actionPlansToFinalize, () => {
      if (actionPlansToFinalize.value.length && actionPlansToFinalize.value.every(e => e.evidenceSeen)) {
        finalizeAllEnabled.value = true
      }
    })

    const activateEvidenceSelector = (index) => {
      evidenceSelector.value[index].click()
      evidenceIndex.value = index
    }

    const changeEvidenceSelected = (e) => {
      actionPlans.value[evidenceIndex.value].evidenceFile = Array.from(e.target.files)
    }

    const addActionPlan = (complaintStage) => {
      if (complaintStage === 'analysis' && !actionPlanType.value) return
      if (complaintStage === 'correctiveMeasures' && !actionPlanTypeCorrectiveMeasures.value) return
      actionPlans.value.push({
        name: '',
        description: '',
        startDate: JSON.parse(JSON.stringify(now)),
        endDate: JSON.parse(JSON.stringify(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000))),
        evidenceFile: [],
        type: complaintStage === 'analysis' ? actionPlanType.value : actionPlanTypeCorrectiveMeasures.value,
        executionResponsible: '',
        createdBy: userData.id,
        complaintStage
      })
    }
  
    const deleteActionPlan = (id, index) => {
      if (id) actionPlansToDelete.push(id)
      actionPlans.value.splice(index, 1)
    }

    const openCase = (id) => {
      const routeData = router.resolve({ name: 'organization-case-view', params: { id } });
      window.open(routeData.href, '_blank');
    }

    const formSubmitted = async () => {
      emit('isSubmitting')

      // Upload evidence files to AWS and then store evidence keys in an array
      for (const p of actionPlans.value) {
        if (!p.evidenceFile?.length) continue

        const uploadEvidencePromises = p.evidenceFile.map(file => {
          if (!file?.name) return null
          return new Promise((resolve, reject) => {
            singleUpload(file, destinationFolder)
              .then((key) => resolve(key))
              .catch((err) => {
                console.log(err)
                resolve(null)
              })
          })
        }) || []

        const evidenceKeys = await Promise.all(uploadEvidencePromises)

        p.evidenceKeys = evidenceKeys.filter(e => !!e)
      }

      const actionPlansCreationPromises = actionPlans.value.map((a, index) => {
        if (a._id) {
          if (a.stage !== 'corrective' && a.stage !== 'implementation' || a.executionResponsible === userData.worker_id.$oid && (a.stage === 'corrective' && stages[props.data.stage] > 4 || a.stage === 'implementation' && stages[props.data.stage] > 7)) {
            updateExistingActionPlan(a, props.data.stage)
          }
          return a._id
        }

        return createNewActionPlan(a, index)
      })

      const newActionPlanIds = await Promise.all(actionPlansCreationPromises)

      const filteredIds = newActionPlanIds.filter(e => !!e)
      
      emit('submit', { ...props.data, actionPlansIds: filteredIds, actionPlansToDelete, isCompletedOverdue })
    }

    const createNewActionPlan = (actionPlan, index) => {
      const newActionPlan = {
        client_id: { link: userData.client.$oid },
        createdBy: { link: actionPlan.createdBy },
        evidence_key: actionPlan.type === 'immediate' && actionPlan.evidenceKeys?.length ? actionPlan.evidenceKeys : null,
        executionResponsible: actionPlan.executionResponsible ? { link: actionPlan.executionResponsible } : null,
        name: actionPlan.name,
        description: actionPlan.description,
        type: actionPlan.type,
        startDate: formatDate(actionPlan.startDate),
        endDate: actionPlan.type === 'in_term' ? formatDate(actionPlan.endDate) : null,
        executionDate: actionPlan.type === 'immediate' ? formatDate(actionPlan.startDate) : null,
        stage: actionPlan.complaintStage === 'analysis' ? 'approval' : actionPlan.type === 'in_term' ? 'implementation' : 'finalized',
        originIndex: index,
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', {
            query: createActionPlan,
            variables: { data: newActionPlan }
          })
          .then(({data}) => {
            if (data.errors) throw new Error(data.errors[0].message)

            // Send email to execution responsible with the details of the action plan created
            newActionPlan.originId = props.data._id
            sendEmailActionPlan(newActionPlan, "add", 'executionResponsible', 'complaint')

            resolve(data.data.insertOneAction_plan._id)
          })
          .catch((error) => {
            console.log(error)
            resolve(null)
          })
      })
    }

    const updateExistingActionPlan = (actionPlan, complaintStage) => {
      let payload
      // console.log(actionPlan)

      switch (actionPlan.stage) {
        case 'analysis':
          payload = {
            executionResponsible: actionPlan.executionResponsible ? { link: actionPlan.executionResponsible } : null,
            name: actionPlan.name,
            description: actionPlan.description,
            startDate: formatDate(actionPlan.startDate),
            endDate: actionPlan.type === 'in_term' ? formatDate(actionPlan.endDate) : null,
            stage: 'approval',
            approvalResult: '',
            approvalComment: '',
          }
          if (actionPlan.type === 'immediate') {
            payload.executionDate = formatDate(actionPlan.startDate)
            payload.evidence_key = actionPlan.evidenceKeys?.length ? actionPlan.evidenceKeys : null
          } else {
            payload.executionDate_unset = true
            payload.evidence_key_unset = true
          }
          break
        case 'approval':
          payload = {
            stage: actionPlan.approvalNextStage,
            approvalResult: actionPlan.approvalResult,
            approvalComment: actionPlan.approvalComment,
            correctiveResult: actionPlan.approvalNextStage === 'corrective' ? '' : null,
            correctiveComment: actionPlan.approvalNextStage === 'corrective' ? '' : null,
            verificationResult: actionPlan.approvalNextStage === 'verification' ? '' : null,
            verificationComment: actionPlan.approvalNextStage === 'verification' ? '' : null
          }
          break
        case 'corrective':
          payload = {
            executionDate: formatDate(actionPlan.executionDate),
            stage: actionPlan.correctiveNextStage,
            evidence_key: actionPlan.evidenceKeys?.length ? actionPlan.evidenceKeys : null,
            correctiveResult: actionPlan.correctiveResult,
            correctiveComment: actionPlan.correctiveComment,
            verificationResult: actionPlan.correctiveNextStage === 'verification' ? '' : null,
            verificationComment: actionPlan.correctiveNextStage === 'verification' ? '' : null
          }
          break
        case 'verification':
          if (complaintStage !== 'verification') break
          payload = {
            stage: actionPlan.verificationNextStage,
            verificationResult: actionPlan.verificationResult,
            verificationComment: actionPlan.verificationComment,
          }
          if (actionPlan.type === 'immediate' && actionPlan.verificationNextStage === 'analysis') {
            payload.evidence_key_unset = true
          }
          if (actionPlan.type === 'in_term' && actionPlan.verificationNextStage === 'corrective') {
            payload.correctiveResult = ''
            payload.correctiveComment = ''
            payload.executionDate_unset = true
            payload.evidence_key_unset = true
          }
          break
        case 'correctiveMeasures':
          payload = {
            executionResponsible: actionPlan.executionResponsible ? { link: actionPlan.executionResponsible } : null,
            name: actionPlan.name,
            description: actionPlan.description,
            startDate: formatDate(actionPlan.startDate),
            endDate: actionPlan.type === 'in_term' ? formatDate(actionPlan.endDate) : null,
            stage: actionPlan.type === 'in_term' ? 'implementation' : 'finalized',
            implementationResult: '',
            implementationComment: '',
            finalizedResult: actionPlan.type === 'immediate' ? '' : null,
            finalizedComment: actionPlan.type === 'immediate' ? '' : null
          }
          if (actionPlan.type === 'immediate') {
            payload.executionDate = formatDate(actionPlan.startDate)
            payload.evidence_key = actionPlan.evidenceKeys?.length ? actionPlan.evidenceKeys : null
          } else {
            payload.executionDate_unset = true
            payload.evidence_key_unset = true
          }
          break
        case 'implementation':
          payload = {
            executionDate: formatDate(actionPlan.executionDate),
            stage: actionPlan.implementationNextStage,
            evidence_key: actionPlan.evidenceKeys?.length ? actionPlan.evidenceKeys : null,
            implementationResult: actionPlan.implementationResult,
            implementationComment: actionPlan.implementationComment,
            finalizedResult: actionPlan.implementationNextStage === 'finalized' ? '' : null,
            finalizedComment: actionPlan.implementationNextStage === 'finalized' ? '' : null
          }
          break
        case 'finalized':
          if (complaintStage !== 'finalized') break
          payload = {
            stage: actionPlan.finalizedNextStage,
            finalizedResult: actionPlan.finalizedResult,
            finalizedComment: actionPlan.finalizedComment,
          }
          if (actionPlan.type === 'immediate' && actionPlan.finalizedNextStage === 'correctiveMeasures') {
            payload.evidence_key_unset = true
          }
          if (actionPlan.type === 'in_term' && actionPlan.finalizedNextStage === 'implementation') {
            payload.implementationResult = ''
            payload.implementationComment = ''
            payload.executionDate_unset = true
            payload.evidence_key_unset = true
          }
          if (actionPlan.finalizedNextStage === 'end' && actionPlan.endDate) {
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            const endDateFormatted = formatDate(actionPlan.endDate, 0)
            if (endDateFormatted < today) isCompletedOverdue = true
          }
          break
        default:
          break
      }

      if (!payload) return

      axios
        .post('/graphql', {
          query: updateActionPlan,
          variables: { query: { _id: actionPlan._id }, data: payload }
        })
        .then(({data}) => {
          if (data.errors) throw new Error(data.errors[0].message)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const addEvidenceSeen = (actionPlanId, stage) => {
      if (stage === 'approval') {
        for (const actionPlan of actionPlansToApprove.value) {
          if (actionPlan._id === actionPlanId) {
            actionPlan.evidenceSeen = true
            actionPlansToApprove.value = [...actionPlansToApprove.value]
            break
          }
        }
      } else if (stage === 'verification') {
        for (const actionPlan of actionPlansToVerify.value) {
          if (actionPlan._id === actionPlanId) {
            actionPlan.evidenceSeen = true
            actionPlansToVerify.value = [...actionPlansToVerify.value]
            break
          }
        }
      } else {
        for (const actionPlan of actionPlansToFinalize.value) {
          if (actionPlan._id === actionPlanId) {
            actionPlan.evidenceSeen = true
            actionPlansToFinalize.value = [...actionPlansToFinalize.value]
            break
          }
        }
      }
    }

    const approveAllActionPlans = () => {
      approveAllCommentEnabled.value = true
      actionPlans.value.forEach(e => {
        if (e.stage === 'approval') e.approvalResult = 'approved'
      })
    }

    const verifyAllActionPlans = () => {
      verifyAllCommentEnabled.value = true
      actionPlans.value.forEach(e => {
        if (e.stage === 'verification') e.verificationResult = 'approved'
      })
    }

    const finalizeAllActionPlans = () => {
      finalizeAllCommentEnabled.value = true
      actionPlans.value.forEach(e => {
        if (e.stage === 'finalized') e.finalizedResult = 'approved'
      })
    }

    const formatKey = (keys) => {
      // return key ? key.slice(key.indexOf('-') + 1) : i18n.t('message.no_evidence_attached')
      return keys?.length ? `${keys.length} ${$t('evidence')}${keys.length > 1 ? 's' : ''}` : i18n.t('message.no_evidence_attached')
    }

    const validateTab = (tabRef, tabName) => new Promise((resolve, reject) => {
      tabRef.validate().then(success => {
        if (success) {
          // Validate that every 'immediate' (in stage 'analysis') and 'in term' (in stage 'corrective') action plans have an evidence selected to upload
          for (const actionPlan of actionPlans.value) {
            if ((actionPlan.type === 'immediate' && (tabName === 'analysis' || tabName === 'correctiveMeasures')
              || actionPlan.type === 'in_term' && actionPlan.executionResponsible === userData.worker_id.$oid
              && (tabName === 'corrective' && actionPlan.stage === 'corrective' && actionPlan.correctiveNextStage === 'verification'
              || tabName === 'implementation' && actionPlan.stage === 'implementation' && actionPlan.implementationNextStage === 'finalized'))
              && !actionPlan.evidenceFile?.length && !actionPlan.evidence_key?.length && !props.readOnly) {
                console.log("not validated", actionPlan)
                showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
                return reject()
            }
          }
          resolve(true)
        }
        else {
          showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
          reject()
        }
      })
    })

    onMounted(() => {
      if (props.readOnly) return

      getWorkersTotalForDropDown()
      getImprovementsForDropDown()
      getLocationsForDropDown()
    })

    return {
      workersTotal,
      improvements,
      locations,
      required,
      form,
      openDocument,
      noDocumentMessage,
      evidenceSelector,
      activateEvidenceSelector,
      changeEvidenceSelected,
      openCase,
      stateOptions,
      actionPlanOptions,
      resultOptions,
      inTermApprovalStageOptions,
      correctiveStageOptions,
      immediateVerificationStageOptions,
      inTermVerificationStageOptions,
      implementationStageOptions,
      immediateFinalizedStageOptions,
      inTermFinalizedStageOptions,
      formSubmitted,
      validateTab,
      identificationTab,
      responsiblesTab,
      analysisTab,
      approvalTab,
      correctiveTab,
      verificationTab,
      correctiveMeasuresTab,
      implementationTab,
      finalizedTab,
      actionPlanType,
      actionPlanTypeCorrectiveMeasures,
      addActionPlan,
      deleteActionPlan,
      pacEnabled,
      pacEnabledCorrectiveMeasures,
      actionPlans,
      userData,
      approveAllEnabled,
      verifyAllEnabled,
      finalizeAllEnabled,
      approveAllCommentEnabled,
      verifyAllCommentEnabled,
      finalizeAllCommentEnabled,
      addEvidenceSeen,
      approveAllActionPlans,
      verifyAllActionPlans,
      finalizeAllActionPlans,
      stages,
      complaintForm,
      formatKey,
      fiveWhyMethodEnabled,
      tabMessages,
      addMessage,
      allActionPlansBeyondAnalysis,
      allActionPlansBeyondCorrectiveMeasures,
      flatPickrConfig,
      flatPickrConfigImmediatePAC,
      inputFileTypes,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>