<template lang="pug">
div
  h5.mb-1 {{ $t('label.messages') }}

  //- User type
  b-row(v-if="userType === 'manager'")
    b-col(md="3")
      b-form-group(:label="$t('User')", :label-for="$t('User')")
        v-select(v-model="userTypeSelected", label="title", :clearable="false", :options="userTypeOptions", :reduce="(type) => type.value", :placeholder="$t('placeholder.user')")

  //- Messages
  ul.list-group
    li(v-if="messages.length === 0", class="list-group-item text-center text-muted font-weight-bold bg-light")
      | {{ $t('message.no_messages') }}
    li.list-group-item(v-for="(message, index) in messagesGroupedByUserType[userType === 'manager' ? userTypeSelected : userType]", :key="index", :class="{ 'user-background': message.sender === 'manager' }")
      div.d-flex.justify-content-between.align-items-center.mb-1
        h6.mb-0 {{ $t(`complaintUserTypes.${message.sender}`) }}
        small.text-muted.font-weight-bold {{ message.createdAt.toLocaleString(`${default_language || 'es'}-US`) }}
      div.d-flex.justify-content-between.align-items-center.mb-1
        span(:style="{ whiteSpace: 'pre-wrap' }") {{ message.text }}
        b-button(
          v-if="message.evidenceKey && message.evidenceKey.length",
          variant="info",
          class="ml-2 px-75 rounded-circle",
          size="sm",
          v-b-tooltip.hover.v-info.top="$t('message.seeEvidence')",
          @click="openDocument(message.evidenceKey)"
        )
          feather-icon(icon="EyeIcon")
  b-row.mt-1
    //- Input
    b-col(md="9", class="mb-1")
      b-form-textarea(
        v-model="newMessage"
        rows="2"
        :disabled="isLoading"
        :placeholder="$t('placeholder.sendMessage')"
      )

    //- Upload Evidence
    b-col(md="3", class="mb-1")
      b-button(
        type="button"
        variant="warning"
        class="px-1"
        v-b-tooltip.hover.v-warning.top="$t('message.attachEvidence')"
        :disabled="isLoading"
        @click="activateFileSelector"
      )
        feather-icon(icon="PaperclipIcon")
      input(
        type="file"
        ref="fileSelector"
        style="display: none"
        :accept="inputFileTypes"
        multiple
        @change="changeFileSelected"
      )
      small(v-if="evidenceFile && evidenceFile.length", class="ml-1")
        | {{ evidenceFile.length === 1 ? evidenceFile[0].name : `${evidenceFile.length} ${$t('evidences')}` }}

  //- Send
  b-row
    b-col(sm="3", class="mb-1")
      b-button(variant="success", :disabled="isLoading || !newMessage", @click="submitMessage")
        | {{ $t('message.sendMessage') }}
    b-col(v-if="isLoading", sm="1", class="mb-1")
      div(:style="{ height: '37px' }")
        loading(
          :active="true" ,
          :is-full-page="false",
          color="#50b692",
          :height="37",
          :width="37"
        )
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { VBTooltip } from 'bootstrap-vue'
import useCommon from '@/views/organization/useCommon'
import store from '@/store'
import awsConnection from '@/views/habit/aws'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { userTypeOptions, inputFileTypes } from '@/constants'
import vSelect from 'vue-select'

export default {
  components: {
    Loading,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    messages: {
      type: Array,
      required: true
    },
    tab: {
      type: String,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { openDocument } = useCommon()
    const { singleUpload } = awsConnection()
    const { showErrorMessage } = useNotifications()
    const userData = store.state?.userStore?.userData || {}
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const newMessage = ref('')
    const fileSelector = ref(null)
    const evidenceFile = ref([])
    const isLoading = ref(false)
    const userTypeSelected = ref('author')
    const messagesGroupedByUserType = computed(() => {
      const groupedMessages = {
        author: [],
        affected: [],
        reported: [],
      }

      props.messages.forEach(message => {
        if (message.sender === 'author' || message.receiver === 'author') {
          groupedMessages.author.push(message)
        } else if (message.sender === 'affected' || message.receiver === 'affected') {
          groupedMessages.affected.push(message)
        } else if (message.sender === 'reported' || message.receiver === 'reported') {
          groupedMessages.reported.push(message)
        }
      })

      return groupedMessages
    })

    const submitMessage = async () => {   
      const message = {
        sender: props.userType,
        receiver: props.userType === 'manager' ? userTypeSelected.value : 'manager',
        text: newMessage.value,
        tab: props.tab,
        createdAt: new Date(),
      }

      if (evidenceFile.value?.length) {
        isLoading.value = true

        const destinationFolder = `${props.clientId || userData.client?.$oid}/complaints`

        const uploadEvidencePromises = evidenceFile.value?.map(file => {
          if (!file?.name) return null
          return new Promise((resolve, reject) => {
            singleUpload(file, destinationFolder)
              .then((key) => resolve(key))
              .catch((err) => {
                console.log(err)
                showErrorMessage(i18n.t('message.evidence_upload_error'))
                resolve(null)
              })
          })
        }) || []

        const evidenceKeys = await Promise.all(uploadEvidencePromises)

        message.evidenceKey = evidenceKeys.filter(e => !!e)

        isLoading.value = false
      }

      emit('add-message', message)
      newMessage.value = ''
      evidenceFile.value = []
    }

    const activateFileSelector = () => {
      fileSelector.value.click()
    }

    const changeFileSelected = (e) => {
      evidenceFile.value = Array.from(e.target.files)
    }

    return {
      newMessage,
      submitMessage,
      fileSelector,
      activateFileSelector,
      changeFileSelected,
      evidenceFile,
      openDocument,
      isLoading,
      userData,
      default_language,
      userTypeSelected,
      userTypeOptions,
      messagesGroupedByUserType,
      inputFileTypes,
    }
  }
}
</script>

<style scoped>
.list-group-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.357rem;
  background-color: #ff9f431f;
}

.list-group-item:hover {
  background-color: #50b6921f;
}

.user-background {
  background-color: #498ceb1f;
}
</style>  